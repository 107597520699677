import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import { dynamicHeadlines } from "../../../utils/url-utils";
import Hero from "../../../components/Hero/hero";
import {
  onlineOrdHero,
  onlineOrdWhiteGlove,
  onlineOrdLargeFeatures,
  onlineOrdCTA,
  onlineOrdTestimonials,
  onlineOrdTrusted,
} from "../../../data/landing/onlineord-data";
import heroBg from "../../../images/hero-images/home-decor-hero.png";
import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";

// const NoVisualsCustom = loadable(() =>
//   import("../../../components/NoVisuals/NoVisualsCustom")
// );

const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);

const whiteGloveImport = "onlineOrd-whiteglove.png";

const OnlineOrd = () => {
  return (
    <Layout isLanding phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="Commission-free online ordering | SpotOn"
        description="Your commission-free solution for online ordering, delivery, and more."
        image={`https://spoton.com/${heroBg}`}
      />
      <Hero
        sectionData={onlineOrdHero}
        heroBg="onlineOrd-decor-hero.png"
        dynamicH1={dynamicHeadlines()}
      />
      <TrustedNumbers numbersArray={onlineOrdTrusted} />
      <section style={{ marginTop: "40px" }}>
        <TestmonialReviews sectionData={onlineOrdTestimonials} />
      </section>
      <PartnerLogos />
      <LargeFeatures
        sectionData={onlineOrdLargeFeatures}
        style={{ marginTop: "128px !important" }}
      />
      <WhiteGlove
        sectionData={onlineOrdWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeCta sectionData={onlineOrdCTA} />
    </Layout>
  );
};

export default OnlineOrd;
